/* :root {
  --primary-color: #18a0df;
  --table-header-bg: #18a0df;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
/* body {
  overflow: hidden;
} */

/* These styles make the root element full-height */
#root {
  display: flex;
  /* height: 100%; */
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  overflow-x: hidden;
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}

.rc-table-thead {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit7-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

/* body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
} */

/* body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
} */

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  /* body.fixed .header-fixed {
    display: none;
  } */
}

.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}

/* Reset Ant Design Input Styles */
.ant-input,
.ant-input-number {
  /* font-family: 'Roboto', sans-serif;  */
  border-radius: 5px;
  /* Match Material-UI border radius */
  border: 1px solid #ced4da;
  /* Match Material-UI border color */
  padding: 8px 12px;
  /* Match Material-UI padding */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* Match Material-UI transition */
}

.ant-input:focus,
.ant-input-number-focused {
  border-color: #80bdff;
  /* Match Material-UI focus border color */
  outline: 0;
  /* Remove Ant Design default focus outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  /* Match Material-UI focus box shadow */
}

.ant-input-number,
.ant-picker {
  width: 100% !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #18a0df;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

::-webkit-scrollbar {
  width: 8;
}

@media all and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888;
  border-radius: 10px; */
  border-radius: 12;
  background-color: "rgba(0,0,0,0)";
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
  background-color: "rgba(0,0,0,0.3)";
  border: "1px solid rgba(255,255,255,0.4)";
}

/* .ant-tabs-tab {




  font-family: Roboto-Medium;

} */
/* 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,

.ant-tabs-tab:hover {

  color: #18a0df !important;

}

.ant-tabs-ink-bar {

  background: #18a0df !important;

}
.ant-select-selector:hover{
  border-color: #18a0df !important;

}
.ant-select-open{
  box-shadow:none;


}
.ant-select-focused{
  border-color: #18a0df !important;
}
.ant-select, .ant-select-in-form-item, .ant-select-status-success, .ant-select-single, .ant-select-allow-clear, .ant-select-show-arrow, .ant-select-show-search{
  box-shadow:none
} */
/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #18a0df;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title,
.ant-drawer-close {
  color: #ffffffd1 !important;
}

.ant-form-item-button {
  margin-bottom: 0 !important;
}

.ant-form-item-label>label {
  /* color: rgb(255 255 255 / 85%) !important; */
}

.ant-input-number .ant-input-number-input {
  padding: 0px 11px !important;
}

/* .anticon .anticon-close{ */
/* position: absolute !important;
color: #5383d3 !important;
right:2;
top: 2; */
/* } */
/* .ant-drawer-header-title{
  flex: 1 !important;
  color: #75b2f5 !important;
  position:absolute !important;
  align-items: center !important;
} */

/* .ant-btn .ant-btn-primary .ant-btn-dangerous{
  background-color: red !important;
} */

.form-card {
  background: #3c3c3c !important;
  border: none !important;
}

.form-card .ant-card-head {
  border-bottom: 1px solid #646464 !important;
}

.form-card .ant-card-head-title {
  color: white !important;
}

.form-card .ant-empty-description {
  color: white !important;
}

.form-card .ant-empty-img-default-path-5 {
  display: none !important;
}

.form-card .ant-empty-img-default-g {
  display: none !important;
}

.sbEntities-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: #d7d7d74d;
}

#sbEntities>div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
}

#sbEntities:hover>div:last-child {
  opacity: 1;
}

.access_rights_form label {
  color: black !important;
}

.ant-form-item-label>label:after {
  content: "" !important;
}

.ant-form-item .ant-form-item-label {
  overflow: auto;
}

.access_rights_form .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}

.access_rights_form .ant-form-item-control {
  flex: 1;
}

.horizontal_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontal_container::-webkit-scrollbar {
  display: none;
}

.ant-form .ant-form-vertical .user_form {
  color: #111;
}

.user_form label {
  color: #18a0df !important;
}

.user_form {
  margin-bottom: 0 !important;
}

.checkout-tabs {
  margin-left: 3rem;
  margin-right: 3rem;
}

.ant-form-horizontal .ant-form-item-label {
  width: 45% !important;
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #18a0df;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #18a0df;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title {
  color: #fff;
}

.ant-drawer-close {
  color: #fff;
}

.date-pos {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px !important;
}

.ant-btn .ant-btn-primary {
  background-color: #18a0df !important;
}

/* .file-upload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.operator-user {
  flex: 1;
  background-color: "#fff";
  height: "90vh";
  overflow-y: "auto";
}

.test {
  width: 400;
  position: fixed;
  top: 50px;
  background-color: #fef5fb;
  z-index: 2;
  align-items: center;
  margin-top: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}

.icon-size {
  width: 20px;
}

.login-background-container {
  background-image: url(./assets/images/document.png);
  background-size: contain;
  width: 100%;
  background-position: center;
}

.my-node-enter {
  opacity: 0;
  transform: scale(0.8);
}

.my-node-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 2000ms, transform 2000ms;
}

.my-node-exit {
  opacity: 1;
  transform: scale(1);
}

.my-node-exit-active {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 2000ms, transform 2000ms;
}

.my-node {
  overflow: hidden;
  /* Hide the scrollbar initially */
}

/* Styles for WebKit browsers */
.my-node::-webkit-scrollbar {
  display: none;
  /* Hide the scrollbar for WebKit browsers */
}

.no_margin_form_input {
  margin-bottom: 0;
}

.custom-table .ant-table-thead>tr>th {
  background-color: #17a2dc;
  color: #fff;
  border-right: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  color: #712e5a !important;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}

.note-word {
  margin-bottom: -2px;
}

.epass-landing-modal {
  width: 400px;
}

.cursor-pointer {
  cursor: pointer;
}

.recent-approvals {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
  /* width: 100%; */
  /* padding: 18px; */
}

.apply-epass {
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  padding: 18px;
}

.small-card-download {
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: 3.8rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
  height: 1122px;
}

.vehicle-number-download {
  font-size: 3.9rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: auto;
  text-align: center;
}

.form-align-end {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pass {
  /* border: 1px solid red; */
  background-color: white;
  width: 210mm;
  height: 300mm;
  font-family: "Verdana";
  z-index: 3;
}

.recent-approval-list {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.epass-download-modal {
  display: flex;
}

.location-section {
  width: 100%;
  padding: 10px;
  place-content: center;
  align-items: center;
  justify-content: center;
}

.location-card1 {
  height: 80px;
  text-align: center;
  background-image: linear-gradient(135deg, #c2e9fb 10%, #a1c4fd 100%);
}

.location-card2 {
  height: 80px;
  text-align: center;
  background-image: linear-gradient(135deg, #c79081 10%, #dfa579 100%);
}

.home {
  position: relative;
  width: 100%;
  /* Adjust as needed */
  height: 100vh;
  /* Adjust as needed */
  background-image: url("./assets/images/home.jpg");
  background-size: cover;
  background-position: center;
  font-family: Montserrat;
}

.home-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.7);
  /* Adjust the overlay color and opacity */
}

.home-content {
  position: relative;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: larger;
  font-weight: bolder;
  color: white;
  display: grid;
  /* Other styles for your content */
}

.transparent {
  background-color: transparent !important;
}

.card-home {
  gap: 30px;
  display: grid;
  justify-content: center;
  /* style={{ gap: "30px" }} */
}

@media only screen and (min-width: 768px) {
  .grid-container {
    display: flex;
    place-content: center;
    justify-content: center;
    gap: 20px;
    /* Gap between grid items */
    align-items: center;
  }

  .welcome {
    display: block;
  }

  .small-card {
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-size: 3.8rem;

    font-weight: bolder;
    text-transform: uppercase;
    color: white;
    height: 1132px;
  }

  .qr-code {
    width: 200px;
    height: 200px;
  }

  .vehicle-number {
    font-size: 3.9rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
  }

  .validity {
    font-size: 25px;
    font-weight: bolder;
  }

  .home-img-content {
    display: flex;
    justify-content: center;
    /* margin: 20px; */
  }

  .home-img {
    height: 80px;
  }

  .home-title {
    display: flex;
    justify-content: center;
    margin: 2px 10px 15px 10px;
  }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
  .vehicle-number {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
  }

  .grid-container {
    display: flex;
    place-content: center;
    justify-content: center;
    gap: 20px;
    /* Gap between grid items */
    align-items: center;
  }

  .welcome {
    display: none;
  }

  .b-font-size {
    font-size: x-small;
  }

  .o-font-size {
    font-size: xx-small;
  }

  .small-card {
    align-items: center;
    justify-content: space-between;
    display: flex;
    font-size: 2rem;

    font-weight: bolder;
    text-transform: uppercase;
    color: white;
    height: 1122px;
  }

  .qr-code {
    width: 50px;
    height: 50px;
  }

  .validity {
    font-size: 18px;
    font-weight: bolder;
  }

  .home-img-content {
    display: flex;
    justify-content: center;
    /* margin: 20px; */
  }

  .home-img {
    height: 80px;
  }

  .home-title {
    display: flex;
    justify-content: center;
    margin: 2px 10px 15px 10px;
  }
}

.dashboard-card {
  font-size: larger;
  font-weight: bolder;
  font-family: Montserrat;
}

.align-center {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.text-white {
  color: white;
  font-weight: bold;
  font-size: small;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-white-smaller {
  color: white;
  /* font-weight: bold; */
  font-size: smaller;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}

.text-align-center {
  text-align: center;
}

.header-style {
  background: -webkit-linear-gradient(left, #4d2d18, #346732);
  background: -o-linear-gradient(right, #4d2d18, #346732);
  background: -moz-linear-gradient(right, #4d2d18, #346732);
  background: linear-gradient(to right, #4d2d18, #346732);
  height: 70px;
  flex: 1;
  overflow-x: hidden;
  width: 100vw;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
}

.styled-table {
  width: 90%;
  border-radius: 5px;
  border-collapse: collapse;
  font-family: Montserrat;
}

.styled-table th,
.styled-table td {
  padding: 8px;
  text-align: left;
}

.styled-table th {
  background-color: #275670;
  color: white;
}

.styled-table tbody tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.styled-table tbody tr:nth-child(even) {
  background-color: #eff8ff;
}

.styled-table tbody td {
  color: black;
}

.ant-form-item {
  /* margin-bottom: 0 !important; */
}

.feedback-text {
  cursor: pointer;
  text-decoration: underline;
}

.text-red {
  columns: red;
}

.maintenance-content {
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-bottom: 0px;
}

.marquee-container {
  /* position: sticky; */
  /* top: 0; */
  height: 50px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.495);
}

.allowed-file-formats {
  color: #555;
  font-size: 12px;
  font-weight: bold;
  margin-top: 6px;
}

.maximum-file-size {
  color: #ff5b6b;
  font-size: 12px;
  font-weight: bold;
}