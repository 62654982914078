* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* */
}

body {
  height: 100vh;
  width: 100%;
}

.login-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: Montserrat;
}

/* .container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("../../../assets/images/home.jpg") no-repeat center;
  background-size: cover;
  filter: blur(50px);
  z-index: -1;
} */
.contact-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.19);
  border-radius: 30px;
}

@media only screen and (min-width: 768px) {
  .left {
    background: url("../../../assets/images/login.jpg") no-repeat center;
    background-size: cover;
    height: 80vh;
    border-radius: 30px;
  }
  .contact-box {
    width: 80vw;
  }

  .login-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      #604432d6,
      #3c6e36d6
    ); /* default overlay color */
    opacity: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 80vh;
    border-radius: 30px;
  }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
  .left {
    background: url("../../../assets/images/login.jpg") no-repeat center;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    height: 50vh;
    border-radius: 30px;
  }
  .contact-box {
    width: 100vw;
  }

  .login-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* default overlay color */
    opacity: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 50vh;
    border-radius: 30px;
  }
}

.left-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.right {
  padding: 25px 40px;
}

h2 {
  position: relative;
  padding: 0 0 10px;
  margin-bottom: 10px;
}

h2:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 4px;
  width: 50px;
  border-radius: 2px;
  background-color: #2ecc71;
}

.field {
  width: 100%;
  outline: none;
  /* padding: 0.5rem 1rem; */
  font-size: 1.1rem;
  margin-bottom: 22px;
  transition: 0.3s;
}

.btn {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #3c6e36;
  color: #fff;
  font-size: 1.1rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

.btn:hover {
  background-color: #27ae60;
}

.field:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}

@media screen and (max-width: 880px) {
  .contact-box {
    grid-template-columns: 1fr;
  }
  /* .left {
    height: 200px;
  } */
}

.login-card {
  position: relative;
  height: 150px;
  --bs-gutter-x: 0 !important;
  cursor: pointer;
}
