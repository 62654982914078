.card {
  position: relative;
  width: 280px;
  height: 150px;
  --bs-gutter-x: 0 !important;
  cursor: pointer;
  border-radius: 10px;
}
.card1 {
  position: relative;
  width: 560px;
  height: 200px;
  --bs-gutter-x: 0 !important;
  cursor: pointer;
  border-radius: 10px;
}

.card-img {
  width: 100%;
  height: auto;
  display: block;
  width: 280px;
  height: 150px;
  border-radius: 10px;
}
.card-img1 {
  width: 100%;
  height: auto;
  display: block;
  width: 560px;
  height: 200px;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* default overlay color */
  opacity: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 280px;
  height: 150px;
  border-radius: 10px;
}
.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* default overlay color */
  opacity: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 560px;
  height: 200px;
  border-radius: 10px;
}
